import { MenuItem } from '@qualtrics/ui-react';
import { getTier1DataTestId } from '../../../Service/testing';
import styles from '../account-menu.scss';
import { messageService } from '../../../Service/message-service';
import React from 'react';

export const getAccountSettings = (
  goToAccountSettings,
  isInPortalApp,
  accountSection,
) => {
  if (!accountSection) return null;

  return (
    <MenuItem
      onSelect={goToAccountSettings}
      data-testid={getTier1DataTestId('Account-Menu-AccountSettings')}
      className={styles.menuItemPortals}
      key={getTier1DataTestId('Account-Menu-AccountSettings')}
    >
      {messageService.get(accountSection.nameKey)}{' '}
      {isInPortalApp && (
        <div className={styles.screenReaderOnly}>
          {messageService.get('OPENS_IN_NEW_TAB_WARNING')}
        </div>
      )}
    </MenuItem>
  );
};
